<template>
  <div class="">
    <div v-if="scriptsLoaded">
      <!-- ApexChart component will be rendered here once scripts are loaded -->
      <apexcharts
        class="chartContainer"
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
        @dataPointSelection="handleClick"
      ></apexcharts>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      scriptsLoaded:false,
      chartOptions: {
        colors: ["#000"],
        chart: {
          fontFamily: '"Inter", sans-serif',
          foreColor: "#2F3747",
          height: 350,

          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          enabled: false,
          style: {
            fontSize: 14,
            fontFamily: '"Inter", sans-serif',
            fontWeight: 800,
            colors: ["#000"],
          },
        },
        fill: {
          opacity: 1,
          type: "solid",
          colors: [
            "#dc9090",
            "#cfd896",
            "#76bcc0",
            "#ed6647",
            "#efbfao",
            "#cfa8c5",
            "#4d5f88",
            "#78bcda",
            "#d2a282",
          ],
        },

        grid: {
          borderColor: "#E3E8EF",
          strokeDashArray: [4, 4],
        },

        yaxis: {
          tickAmount: 2, // Display only 2 ticks on the y-axis
          min: 0, // Set the minimum value of the y-axis
          max: 100, // Set the maximum value of the y-axis
          tickPlacement: "between", // Place the ticks between the bars
          labels: {
            formatter: function (value) {
              return value + "%";
            },
            style: {
              colors: [],
              cssClass: "apexcharts-yaxis-label font-weight-bold",
            },
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "95%", // Set the column width to 100% to remove the gap between bars
            distributed: true,
            dataLabels: {
              enabled: false,
              position: "top",
              hideOverflowingLabels: true,
              orientation: "horizontal",
            },

            borderRadius: 0,
          },
        },
        tooltip: {
          enabled: false, // Set tooltip enabled to false
        },
        stroke: {
          curve: "straight",
          width: 0,
        },
        xaxis: {
          categories: [
            "Energy & Focus",
            "Mental Health",
            "Pain & Inflammation",
            "Fitness & Weight Loss",
            "Nervous System",
            "Hormonal Health",
            "Sleep",
            "Immune Support",
            "Gut Health",
          ],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Montserrat",
              cssClass: "apexcharts-xaxis-label font-weight-bold",
            },
            offsetX: 0,
            offsetY: 0,
            format: undefined,
            formatter: undefined,
          },
          axisBorder: {
            offsetY: -1,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },

        // Add more styling options as per your requirements
      },
      series: [
        {
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.setAttribute('src', src);
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    handleClick(event, chartContext, config) {
      if (config.dataPointIndex !== undefined) {
        // Handle the click event for the specific bar
        let links = [
          "https://view.flodesk.com/pages/64aea7b126d35119ca2dcb5c",
          "https://view.flodesk.com/pages/64aeab20d3c6fd165d588dc5",
          "https://view.flodesk.com/pages/64afbbf2d3c6fd165d588f3e",
          "https://view.flodesk.com/pages/64aea71926d35119ca2dcb5a",
          "https://view.flodesk.com/pages/64afbc84d3c6fd165d588f40",
          "https://view.flodesk.com/pages/64aea806d3c6fd165d588dc0",
          "https://view.flodesk.com/pages/64a13a928300d0729807ccf2",
          "https://view.flodesk.com/pages/64aea883d3c6fd165d588dc1",
          "https://view.flodesk.com/pages/64aeabbb26d35119ca2dcb63",
        ];
       

        window.open(links[config.dataPointIndex], "_blank");
      }
    },
    initApexCharts() {
      this.loadScript('https://cdn.jsdelivr.net/npm/apexcharts')
        .then(() => {
          return this.loadScript('https://cdn.jsdelivr.net/npm/vue-apexcharts');
        })
        .then(() => {
         

          // Registering ApexCharts as a global component
          Vue.component('apexcharts', window.VueApexCharts);
          this.scriptsLoaded = true;
          return 
          
        })
        .catch(err => {
          throw err
        });
    },
  },
  computed: {
    ...mapGetters(["getTotalPreviewPages", "getSelectedOptions"]),

    getSelectedOptionsData() {
      return this.getSelectedOptions.questionAnswer;
    },
    getPreviewPages() {
      return this.getTotalPreviewPages;
    },

    getTotalQuestionPages() {
      let QuestionPages = this.getPreviewPages.filter(
        (x) => x.value == "singleAnswer" || x.value == "multipleAnswer"
      );

      return QuestionPages;
    },

    CalculateOptionIndexArray() {
      const selectedOptionsData = this.getSelectedOptionsData;
      let optionsIndexArray = [];

      selectedOptionsData.forEach((item) => {
        const matchingPage = this.getTotalQuestionPages.find(
          (page) => page.id === item.questionId
        );

        if (matchingPage) {
          let optionIndex = matchingPage.pageDesign.blocksArray.findIndex(
            (block) => block.type == "option"
          );

          if (optionIndex > -1) {
            let optionId = item.selectedOption.id;

            let selectedOptionIndex = matchingPage.pageDesign.blocksArray[
              optionIndex
            ].options.findIndex((opt) => opt.id == optionId);

            if (selectedOptionIndex > -1) {
              optionsIndexArray.push(selectedOptionIndex);
            } else {
              optionsIndexArray.push(0);
            }
          }
        }
      });

      return optionsIndexArray;
    },

    GroupArrayValue() {
      let optionIndexArray = [...this.CalculateOptionIndexArray];

      const groupSumArray = [];
      for (let i = 0; i < optionIndexArray.length; i += 4) {
        const pairSum = optionIndexArray
          .slice(i, i + 4)
          .reduce((acc, curr) => acc + curr, 0);
        groupSumArray.push(pairSum);
      }

      return groupSumArray;
    },

    CalculateFinalPercentArray() {
      const groupArray = [...this.GroupArrayValue];

      let percentArray = groupArray.map((x) => Math.round((x / 12) * 100));

      return percentArray;
    },
  },
  mounted() {
    this.series = [{ data: this.CalculateFinalPercentArray }];
    this.initApexCharts();
    //
  },
};
</script>

<style scoped>
.chartContainer >>> .apexcharts-canvas svg .apexcharts-bar-series {
  cursor: pointer !important;
}
</style>
